<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">Offers</h4>
              </div>
              <div class="back-btn">
                <router-link :to="{ name: 'offers' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{ title }}</span>
              </div>
            </div>

            <div class="create_items_section">
              <form @submit.prevent="createOffer">
                <div class="row">
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <label>Title (For internal use)</label>
                        <div>
                          <input class="form-control" v-model="title" @blur="v$.title.$touch">
                        </div>
                        <div class="input_error" v-if="v$.title.$error">This field is required.</div>
                      </div>
                    </div>

                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Applicable To</h2>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="radio-inline offer-radio">
                              <input v-model="applicableTo" type="radio" id="vegan" :value="1" />
                              Products &nbsp;
                            </label>

                            <label class="radio-inline offer-radio">
                              <input v-model="applicableTo" type="radio" id="vegan" :value="2" />
                              Orders &nbsp;
                            </label>

                          </div>

                          <div v-if="applicableTo ==1">
                            <div class="form-group">
                              <label for="quantity">Condition</label>
                              <select class="form-control" v-model="quantityType" id="">
                                <option value="1">Equal to</option>
                                <option value="2">More than</option>
                                <option value="3">Flat rate</option>
                              </select>
                            </div>

                            <div class="form-group">
                              <label for="quantity">Quantity</label>
                              <input type="text" v-model="Itemquantity" class="form-control">
                            </div>

                          </div>
                          <div v-else>
                            <div class="form-group">
                              <label for="">For value Above</label>
                              <input type="text" class="form-control" v-model="valueAbove">
                            </div>
                            <div class="form-group">
                              <label class="radio-inline offer-radio">
                                <input v-model="orderType" type="radio" id="vegan" :value="1" />
                                On Order Amount &nbsp;
                              </label>

                              <label class="radio-inline offer-radio">
                                <input v-model="orderType" type="radio" id="vegan" :value="2" />
                                On Delivery Cost &nbsp;
                              </label>

                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Customer get</h2>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group" v-if="applicableTo">
                            <label>
                              Discount Amount:
                            </label>
                            <div>
                              <input type="text" v-model="discountVal" class="form-control discount_input" :disabled="offerType ==1 && applicableTo ==1 ? true :false">
                              <select class="form-control discount_type" id="" v-model="offerType" :disabled="offerType ==1 && applicableTo == 1? true :false">
                                <option value="3">%</option>
                                <option value="4">{{currency}}</option>
                              </select>

                            </div>
                          </div>

                          <div class="form-group" v-if="applicableTo ==1">
                            <label class="radio-inline offer-radio">
                              <input v-model="bogof" type="checkbox" id="vegan" value="true" />
                              Buy One, Get One Free offer (BOGOF) &nbsp;
                            </label>

                          </div>

                          <div class="form-group">
                            <label class="radio-inline offer-radio">
                              <input v-model="recurrence" type="checkbox" id="recurrence" value="true" />
                              Recurring (Offer to return on a set frequency) &nbsp;
                            </label>

                          </div>
                          <div class="recurring_offer mt-2" v-if="recurrence">
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-3">
                                  <label for="offer_frequency">Offer Frequency</label>
                                </div>
                                <div class="col-md-7">
                                  <select v-model="recurring" class="form-control">
                                    <option value="1">Monthly</option>
                                    <option value="2">Annually</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <div class="col-md-5  mt-1">
                                  <Datepicker placeholder="Start Date" v-model="startDate"></Datepicker>
                                </div>
                                <div class="col-md-5   mt-1">
                                  <Datepicker placeholder="End Date" v-model="endDate" :minDate="fstartDateTime"></Datepicker>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Select Product by</h2>
                      </div>
                      <div :class="['row', {'disabled_content':applicableTo==2}]">
                        <div class="col-md-12">
                          <div class="form-group">
                            <button type="button" class="btn btn-purple btn-block" data-toggle="modal" data-target="#productM"> Single Product</button>
                          </div>

                          <div class="form-group" v-if="offerType !=1">
                            <button type="button" class="btn btn-purple btn-block" data-toggle="modal" data-target="#catModal"> Categories</button>
                          </div>
                          <div class="form-group">
                            <button type="button" class="btn btn-purple btn-block"> Advanced Filters</button>
                          </div>

                        </div>
                        <!-- modal -->
                        <!-- product -->
                        <div class="modal fade" id="productM" tabindex="-1" role="dialog" aria-labelledby="productM" aria-hidden="true">
                          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">

                              <div class="">

                                <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <h5 class="modal-title" id="exampleModalLongTitle">{{offerType == 1 && scroll == 1 ? 'Add Primary Products' :  offerType == 1 && scroll == 2 ? 'Add Free Products' : 'Add Products' }}</h5>
                                      <button type="button" id="productM_close" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <input aria-invalid="false" v-model="itemSearch" @keyup="searchItems()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                    </div>

                                  </div>
                                </div>
                                <hr>
                                <div v-show="selectedItems.length > 0">
                                  <div class="selected_item pd-0" v-if="offerType == 1 && scroll == 2">
                                    <ul>
                                      <li><strong>Selected products</strong></li>
                                      <li v-for="item in selectedItems" :key="item.menu_item.id"><input type="checkbox" checked> &nbsp; {{item.menu_item.item_name}}</li>
                                      <!-- <li><input type="checkbox" checked> &nbsp; Contact</li>
                                    <li><input type="checkbox" checked> &nbsp; About</li> -->
                                    </ul>
                                  </div>
                                </div>

                                <div class="scrollable_content pr-3 pl-3 pt-0 pb-0">
                                  <div :class="['item_list',{'disabled_content': itemLoading}]">
                                    <div v-for="item in items" :key="item.menu_item.id" :class="['menu_item_list',{'disabled_content':selectedItemsId.indexOf(item.menu_item.id) != -1 && disableSelected}]">
                                      <label class="checkbox-inline">
                                        <input type="checkbox" @click="selectItems(item)" :id="`item_${item.menu_item.id}`">&nbsp; <img src="" alt=""> &nbsp; {{item.menu_item.item_name}}
                                      </label>
                                    </div>
                                    <div v-if="totalPages > 1">
                                      <Pagination :total-pages="totalPages" :per-page="itemsize" :current-page="itemPage" @pagechanged="onItemPageChange" />
                                    </div>

                                  </div>

                                </div>
                              </div>
                              <hr>
                              <div class="pb-4 pt-4 pr-3 pl-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <span v-if="selectItemsCount > 0">{{selectItemsCount > 1 ? selectItemsCount + ' products selected' : selectItemsCount + ' product selected'}}</span> <span v-if="selectFreeItemsCount > 0"> &nbsp;&nbsp; + {{selectFreeItemsCount > 1 ? `${selectFreeItemsCount} Free Products` : `${selectFreeItemsCount} Free Product`}}</span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <div class="mt-2" v-if="offerType == 1">
                                      <span><strong>BOGOF Options</strong></span>
                                      <div class="bogof_option mt-2">
                                        <!--  -->
                                        <table>
                                          <tr>
                                            <td>
                                              <label class="radio-inline offer-radio">
                                                <input v-model="itemType" type="radio" id="vegan" value="same" />
                                                Free item as Original &nbsp;
                                              </label>
                                            </td>
                                            <td>
                                              <label class="radio-inline offer-radio">
                                                <input v-model="itemType" type="radio" id="vegan" value="different" />
                                                Free item is different &nbsp;
                                              </label>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <label class="radio-inline offer-radio">
                                                <input v-model="accompaniments" type="radio" id="vegan" :value="true" />
                                                Accompaniments included &nbsp;
                                              </label>
                                            </td>
                                            <td>
                                              <label class="radio-inline offer-radio">
                                                <input v-model="accompaniments" type="radio" id="vegan" :value="false" />
                                                No Accompaniments &nbsp;
                                              </label>
                                            </td>
                                          </tr>
                                        </table>
                                        <!--  -->

                                      </div>

                                    </div>
                                  </div>
                                  <div class="col-md-3 ">
                                    <div class="offer_btn_grp">
                                      <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-dgrey mr-2">cancel</button>
                                      <button type="button" :class="['btn',itemSelected ? 'btn-orange' : 'btn-light']" @click="addSelectedItems()">Add</button>
                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- endproducts -->
                        <!-- category modal -->
                        <div class="modal fade" id="catModal" tabindex="-1" role="dialog" aria-labelledby="catModal" aria-hidden="true">
                          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">

                              <div class="">

                                <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <h5 class="modal-title" id="exampleModalLongTitle">Add Category</h5>
                                      <button type="button" id="catprod_close" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <input aria-invalid="false" v-model="catSearch" @keyup="searchCategories()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                    </div>

                                  </div>
                                </div>
                                <hr>
                                <div class="scrollable_content pr-3 pl-3 pt-0 pb-0">
                                  <div :class="['item_list',{'disabled_content': catLoading}]">
                                    <div v-for="cat in categories" :key="cat.id" class="menu_item_list">
                                      <label class="checkbox-inline">
                                        <input type="checkbox" v-if="catselected == false || catselected == cat.id" :id="`cat_${cat.id}`" @click="selectCategories(cat)">&nbsp; <img src="" alt=""> &nbsp; {{cat.title}}
                                      </label>
                                    </div>
                                    <div v-if="totalPages > 1">
                                      <Pagination :total-pages="catTotalPages" :per-page="catsize" :current-page="catPage" @pagechanged="onCatPageChange" />
                                    </div>

                                  </div>

                                </div>
                              </div>
                              <hr>
                              <div class="pb-4 pt-4 pr-3 pl-3">
                                <div class="row">
                                  <div class="col-md-9"></div>
                                  <div class="col-md-3 ">
                                    <div class="offer_btn_grp">
                                      <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-dgrey mr-2">cancel</button>
                                      <button type="button" :class="['btn',catselected !=false ? 'btn-orange' : 'btn-light']" @click="addCatItems()">Add</button>
                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end -->
                        <!-- end -->
                      </div>

                    </div>
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Channel</h2>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label  :class="['checkbox-inline',{'disabled_content':applicableTo==2}]">
                              <input v-model="allChannelSelected" type="checkbox" id="channel_all" value="all"  :disabled="otherChannelSelected"> &nbsp; All &nbsp; &nbsp;
                            </label>
                            <label :class="['checkbox-inline',{'disabled_content':applicableTo==2}]">
                              <input v-model="checkedChannel[0]" type="checkbox" id="channel_0"  value="0"  :disabled="allChannelSelected"> &nbsp; Foodie &nbsp; &nbsp;
                            </label>
                            <label  :class="['checkbox-inline',{'disabled_content':applicableTo==2}]" v-for="channel in channels" :key="channel.id">
                              <input v-model="checkedChannel[channel.id]" type="checkbox" :id="'channel_'+channel.id"   :disabled="allChannelSelected"> &nbsp; {{channel.channel_name}} &nbsp; &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Select Branches To include</h2>
                      </div>
                      <div class="row">
                        <div class="col-md-12">

                          <div class="form-group">
                            <span :class="['badge badge_review mr-2',{'disabled_content': branchSelected ? true : false}]">
                              <input type="checkbox"  @click="addedBranches('all')"   :id="`all_stores`" value="all" >&nbsp;All&nbsp;&nbsp;
                            </span>

                            <span :class="['badge badge_review mr-2',{'disabled_content': allselected ? true : false}]" v-for="store in stores" :key="store.id" >
                              <input type="checkbox" @click="addedBranches(store.id)"  :id="`selected_store_${store.id}`" :value="store.id" >&nbsp; {{store.branch_name}}&nbsp;&nbsp;
                            </span>

                          </div>


                        </div>
                      </div>
                    </div>


                    <div class="pl-5 pr-4 bd-b" v-if="!recurrence">
                      <div class="form-group">
                        <h2 class="section-title"><input type="checkbox" v-model="scheduleTime" :value="false">&nbsp; Schedule Sale</h2>
                      </div>
                      <div class="row" v-if="scheduleTime">
                        <div class="col-md-12">
                          <div class="form-group">
                            <div class="offer_date_picker mr-2 mt-1">
                              <Datepicker placeholder="Start Date" v-model="startDate" :start-time="startTime" autoApply></Datepicker>
                            </div>
                            <div class="offer_date_picker  mt-1">
                              <Datepicker placeholder="End Date" v-model="endDate" :start-time="endTime" :minDate="startDate" autoApply></Datepicker>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="row" v-if="scheduleTime">
                        <div class="col-md-12">
                          <div class="form-group">
                            <!--  -->
                            <span class="badge badge_review mr-2" v-for="day in days" :key="day.key">
                              <input type="checkbox" @click="addDays(day.key)" :id="`days_of_work${day.key}`" :value="day.key" :checked="selectedDays.length > 0 && selectedDays.filter((x) => x == day.key).length > 0 ? true : false">&nbsp; {{day.value}}&nbsp;&nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- dates error -->
                      <div v-if="v$.startDate.$error || v$.endDate.$error">
                        <div class="input_error" v-if="v$.startDate.$error">Start Date field is required.</div>
                        <div class="input_error" v-if="v$.endDate.$error">End Date field is required.</div>
                      </div>

                    </div>
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Product Prices when Sale Campaign ends</h2>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="radio-inline offer-radio">
                              <input type="radio" id="vegan" v-model="productReturnPrice" :value="false" />
                              Return to original Prices &nbsp;
                            </label>
                          </div>
                          <div class="form-group">
                            <label class="radio-inline offer-radio">
                              <input type="radio" id="vegan" v-model="productReturnPrice" :value="true" />
                              Return to current Prices &nbsp;
                            </label>
                          </div>
                          <div class="mt-2">
                            <p>Check option <strong>Return to original Prices</strong> if you want your products to return to their origin level after the campaign ends.</p>
                            <p class="mt-2">
                              Check option <strong>Return to current Prices</strong> if you want your products to their current level rigth before this campaign
                            </p>
                            <p class="mt-2">For example: Your product is currently 20% off and you are now creating a 30% sale. If you want your prices to return to 20% off (``current level``) after the 30% sale ends you have to check this option
                              . If you want your prices return to their original level (MSRP) check 'Return to original Prices'
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="pl-5 pr-4 pt-4 mb-4">
                      <div class="form-group">
                        <h2 class="section-title">
                          <input type="checkbox" v-model="overrideCent"> Override Cents
                        </h2>
                        <div v-if="overrideCent">
                          <div class="form-group">
                            <select v-model="roundingType" class="form-control discount_input">
                              <option value="0">Select Rounding Type</option>
                              <option value="1">Manual Override</option>
                              <option value="2">Rounding Down</option>
                              <option value="3">Rounding Off</option>
                              <option value="3">Rounding Up</option>
                            </select>
                          </div>
                          <div v-if="roundingType == 1">
                            <input type="text" class="form-control discount_input" v-model="roundingVal" placeholder="value to override cents">
                          </div>

                        </div>
                      </div>
                      <div class="form-group">
                        <h2 class="section-title">
                          <input type="checkbox" v-model="generateCategory"> Generate Category
                        </h2>

                      </div>
                      <!-- <div class="form-group">
                        <h2 class="section-title">
                          <input type="checkbox" v-model="generateTag"> Generate Offer Tag
                        </h2>

                      </div> -->
                      <div class="form-group">
                        <label>
                          Parent Offer
                        </label>
                        <div>
                          <select class="form-control discount_input" id="" v-model="parrentOffer">
                            <option value="">--select parent offer --</option>
                            <option v-for="offer in offers" :key="offer.id" :value="offer.id">{{offer.name}}</option>
                          </select>

                        </div>

                      </div>

                      <div class="form-group">
                        <label>
                          Add To Category
                        </label>
                        <div>
                          <select class="form-control discount_input" id="" v-model="offerCategory">
                            <option value="">--select Category--</option>
                            <option v-for="cat in mainCategories" :key="cat.id" :value="cat.id">{{cat.title}}</option>
                          </select>

                        </div>

                      </div>

                      <div class="form-group">
                        <label>Description(optional)</label>
                        <div>
                          <editor v-model="description" :init="{
                            height: 300,
                            branding: false
                          }" />
                        </div>
                      </div>

                    </div>

                  </div>

                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Summary</h2>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-5">Title:</div>
                          <div class="col-md-7">{{title ? title : 'No information entered yet.'}}</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-5">Customers get:</div>
                          <div class="col-md-7">{{(offerType == 1 || offerType == 2 || offerType == 5)? 'bogof' : offerType == 3 ? 'percentage discount' : offerType == 4 ? 'Value Discount' : ''}}</div>
                        </div>
                      </div>
                      <!-- tags area -->

                      <div class="tag_area">
                        <div v-if="products.length > 0">
                          <ol class="selected-cat tag_sec">
                            <li><span @click="removeProducts()">Tag <i class='fa fa-trash-o'></i></span></li>
                          </ol>
                        </div>
                      </div>
                      <!-- end tags area -->

                      <!-- parent items -->
                      <div class="parent_items_row mt-2" v-if="products.length > 0">
                        <span v-if="offerType == 1"><strong>Primary Product</strong></span>
                        <div v-for="(item, itemindx) in products" :key="item.menu_item.id" class="menu_item_list" @drop="onDrop($event,item)" @dragover.prevent @dragenter.prevent>
                          <div class="p_item_image mr-1"></div>
                          <div class="p_item_desc">
                            <h2>
                              {{(itemindx + 1)}} . {{item.menu_item.item_name}}
                              <span :style="itemStatusStyles(item.menu_item)">
                                {{itemStatusName(item.menu_item)}}
                              </span>
                            </h2>
                            <div class="p_remove">
                              <span @click="removeProduct(item.menu_item.id)"><i class='fa fa-trash-o'></i></span>
                            </div>
                            <div class="offer_prices row">
                              <span :class="['col-3 pad-r-0',{'strike_text': offerType !='bogof' && item.menu_item.original_price > 0 && item.menu_item.original_price != getnewPrice(item.menu_item.original_price)}]">
                                <!-- {{ item }} -->
                                Original Price: {{pesapalConfig.currency_code+" "+ item.menu_item.price}}
                              </span>
                              <div class="col-3 pad-r-0">
                                <span style="display: inline-block;">Custom Original Price: <small>(overriding item price)</small> </span>
                                <input type="text" v-model="itemPriceOverride[item.menu_item.id]" class="offer_pos_code_price">
                              </div>
                              <span class="col-3 pad-r-0" v-if="offerType !='bogof' && item.menu_item.original_price > 0 && item.menu_item.original_price != getnewPrice(item.menu_item.original_price)">Offer Price: {{pesapalConfig.currency_code}} {{ getnewPrice(item.menu_item.original_price)}}</span>
                              <div class="col-3 pad-r-0"><span style="display: inline-block;">Custom Price:</span>
                                <input type="text" v-model="itemPrice[item.menu_item.id]" class="offer_pos_code_price">
                              </div>
                            </div>

                            <div class="offer_codes row" v-if="appConfigs.offer_type == 1">
                              <div class="col-12 pad-r-0">
                                <span style="display: inline-block;"> <strong> Discount Code:</strong></span>
                                <input type="text" v-model="discountCode[item.menu_item.id]" class="offer_pos_code_price">
                              </div>
                            </div>

                            <div class="offer_codes">
                              <div class="row">
                                <div class="col-md-12">
                                  <h2 class="section-title">
                                    <input type="checkbox" :id="`item_tag_${item.menu_item.id}`" v-model="generateItemTag[item.menu_item.id]"> Generate Offer Tag
                                  </h2>
                                </div>
                              </div>

                              <div class="offer_tag" v-if="generateItemTag[item.menu_item.id] == true">
                                <div class="row mt-2" v-for="channel in channels" :key="channel.id">
                                  <div class="col-ld-12 col-md-12 col-sm-12">
                                    <input :id="'channel_'+channel.channel_name" type="checkbox" class="mr-2" @click="addTags(channel)" ><label>{{channel.channel_name}}</label>
                                    <div class="row ml-4" v-if="offerTags[channel.channel_name] !=undefined && offerTags[channel.channel_name].length > 0">
                                      <div class="col-md-12 mb-2">
                                        <span class="text-muted"> <i><small>(please select associated  offer tag below:)</small></i></span>
                                      </div>

                                      <div class="col-md-4" v-for="(tag, sindex) in offerTags[channel.channel_name]" :key="sindex">
                                        <input :id="'c_tag_'+tag" type="checkbox" v-model="tagSelected[item.menu_item.id+channel.channel_name+tag]" @click="addSelectedTag(item.menu_item.id,channel,tag)"  ><label> &#160; {{tag}}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-4 mb-1">
                                <h2>{{item.menu_item.item_name}} Pos Codes :</h2>
                              </div>
                              <hr>

                              <div class="row mt-2">
                                <div class="col-md-4 pad-r-0" v-for="config in posConfigs" :key="config.id">
                                  <strong><span class="pos_code">{{config.name}} <span v-if="config.version"><small>({{ config.version }})</small></span>:</span></strong>  <input type="text" v-model="itemPos[`${item.menu_item.id}_${config.id}`]" class="offer_pos_code">
                                </div>
                                <!-- {{ posConfigs }} -->


                                <div class="col-md-12">

                                  <div v-if="productError == item.menu_item.id">
                                    <span class="disabled">
                                      please fill code
                                    </span>
                                  </div>
                                </div>

                              </div>

                              <div class="mt-4">
                                <span data-toggle="modal" :data-target="`#modifier_group_${item.menu_item.id}`"><i class="fa fa-plus mr-2"></i> Add Item Modifiers
                                </span>
                              </div>
                              <!-- modifiers group -->
                              <div class="item_modfier mt-3">
                                <div v-for="(mod, modindex) in modifiers.filter((x) => x.item_id == item.menu_item.id)" :key="mod.mod_id" class="modifier_item_list" draggable="true" @drop="onModDrop($event,modindex,item.menu_item.id,'')" @dragenter.prevent @dragover.prevent @dragstart="startModDrag($event,modindex)" style="cursor:pointer;">
                                  <p>
                                    <span data-toggle="collapse" :data-target="'#modifier_'+mod.mod_id" aria-expanded="false" aria-controls="collapseExample"> <b>{{mod.mod_name}} &nbsp;&nbsp;</b> <i class="float-right fa fa-trash-o" @click="removingModifier(item.menu_item.id,mod.mod_id)"></i> <i class='float-right mr-2 far fa-edit '></i></span>
                                  </p>
                                  <div class="collapse mt-2" :id="`modifier_${mod.mod_id}`">
                                    <div class="row mt-4" v-for="itm  in mod.items" :key="itm.id">

                                      <div class="col-md-2">
                                        <span>
                                          {{ itm.name }}
                                        </span>
                                      </div>
                                      <div class="col-md-5">
                                        <label>Pos ID</label>
                                        <div v-for="config in posConfigs" :key="config.id">

                                          <div class="form-group">
                                            <label for="code">{{ config.name }} <span v-if="config.version"><small>({{ config.version }})</small></span>:</label>
                                            <input class="offer_pos_code" v-model="groupItemPos[`${item.menu_item.id}_${itm.id}_${config.id}_${mod.mod_id}`]">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="form-group">
                                          <label for="price">Price:</label>
                                          <input class="offer_pos_code" v-model="groupItemPrice[`${item.menu_item.id}_${itm.id}`]">
                                        </div>
                                      </div>
                                      <div class="col-md-1">
                                        <span class='float-right' @click="removeModifierItem(item.menu_item.id,mod.mod_id,itm.id)"><i class='fa fa-trash-o'></i></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <!--  -->
                              <!-- modifier modals -->
                              <div class="modal fade" :id="`modifier_group_${item.menu_item.id}`" tabindex="-1" role="dialog" aria-labelledby="modifier_group" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="exampleModalLongTitle">Add Modifier Group ({{item.menu_item.id}})</h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <div class="search_content mb-3">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <input aria-invalid="false" v-model="search" @keyup="searchModifiers()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="scrollable_content">
                                        <div class="row">
                                          <div class="col-md-12">

                                            <table class="table">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Modifier Group</th>
                                                  <th>Options</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="(modifiergroup, mdgindex) in modifiersGroups" :key="mdgindex">

                                                  <td>
                                                    <input @click="addItemMofiers(modifiergroup,item.menu_item.id)" :id="'mod_'+modifiergroup.id+item.menu_item.id" type="checkbox">
                                                  </td>
                                                  <td>{{ modifiergroup.name }}</td>
                                                  <td>
                                                    <div v-if="modifiergroup.choices != null">
                                                      <span v-for="(item, itimindex) in modifiergroup.choices" :key="itimindex">{{
                                                                                                                                  item.item_name
                                                                                                                                }}
                                                        <span v-if="itimindex + 1 < modifiergroup.choices.length">, </span>
                                                      </span>
                                                    </div>
                                                    <div v-else>
                                                      <span>-</span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" data-dismiss="modal" aria-label="Close" @click="addItemsModifiers(item.menu_item.id)" class="btn btn-primary custom-orange">Save</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--  -->
                              <!-- end modifier group-->
                              <!--  -->
                              <!-- attached free item -->
                              <div v-if="productFreeItem[item.menu_item.id] != undefined && productFreeItem[item.menu_item.id].length > 0">
                                <div class="attached_menu_item_list pl-0 mt-2" v-for="product in productFreeItem[item.menu_item.id]" :key="product">
                                  <div class="pl-5">
                                    <div class="p_item_image mr-1">
                                      <!-- <i class="fas fa-arrows-alt"></i> -->
                                    </div>
                                    <div class="p_item_desc">
                                      <h2> {{product.menu_item.item_name}} &nbsp;<em>(Free)</em></h2>
                                      <div class="p_remove"><span @click="removeAttachedItem(item.menu_item.id,product.menu_item.id)"><i class='fa fa-trash-o'></i></span>
                                      </div>
                                      <div class="offer_prices">
                                        <span class="mr-4 strike_text">{{pesapalConfig.currency_code+" "+product.menu_item.price}}</span>

                                      </div>

                                      <div class="offer_codes row" v-if="appConfigs.offer_type == 1">
                                        <div class="col-12 pad-r-0">
                                          <span style="display: inline-block;"> <strong> Discount Code:</strong></span>
                                          <input type="text" v-model="FreeItemdiscountCode[`${item.menu_item.id}_${product.menu_item.id}`]" class="offer_pos_code_price">
                                        </div>
                                      </div>

                                      <div class="offer_codes">


                                        <!-- new -->

                                        <div class="mt-3 mb-1">
                                          <h2>{{product.menu_item.item_name}} Pos Codes :</h2>
                                        </div>
                                        <hr>

                                        <div class="row mt-2">
                                          <div class="col-md-6 pad-r-0" v-for="config in posConfigs" :key="config.id">
                                            <strong><span class="pos_code">{{config.name}} <span v-if="config.version"><small>({{ config.version }})</small></span>:</span></strong>  <input type="text" v-model="itemPos[`${item.menu_item.id}_${product.menu_item.id}_${config.id}`]" class="offer_pos_code">
                                          </div>
                                          <!-- {{ posConfigs }} -->


                                          <div class="col-md-12">

                                            <div v-if="productError == item.menu_item.id">
                                              <span class="disabled">
                                                please fill code
                                              </span>
                                            </div>
                                          </div>

                                        </div>

                                        <!-- child modifier -->
                                        <div class="mt-4">
                                          <span type="button" data-toggle="modal" :data-target="`#free_modifier_group_${product.menu_item.id}_${item.menu_item.id}`"><i class="fa fa-plus mr-2"></i> Add Item Modifiers
                                          </span>
                                        </div>
                                        <!-- modifier modals -->
                                        <div class="modal fade" :id="`free_modifier_group_${product.menu_item.id}_${item.menu_item.id}`" tabindex="-1" role="dialog" aria-labelledby="modifier_group" aria-hidden="true">
                                          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLongTitle">Add Modifier Group</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body">
                                                <div class="search_content mb-3">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                      <input aria-invalid="false" v-model="search" @keyup="searchModifiers()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="scrollable_content">
                                                  <div class="row">
                                                    <div class="col-md-12">

                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>#</th>
                                                            <th>Modifier Group</th>
                                                            <th>Options</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr v-for="(modifiergroup, mdgindex) in modifiersGroups" :key="mdgindex">
                                                            <td>
                                                              <input @click="addFreeItemModifier(modifiergroup,product.menu_item.id,item.menu_item.id)" type="checkbox" :id="`free_mod_${modifiergroup.id}_${product.menu_item.id}_${item.menu_item.id}`">
                                                            </td>
                                                            <td>{{ modifiergroup.name }}</td>
                                                            <td>
                                                              <div v-if="modifiergroup.choices != null">
                                                                <span v-for="(item, itimindex) in modifiergroup.choices" :key="itimindex">{{
                                                                                                                                            item.item_name
                                                                                                                                          }}
                                                                  <span v-if="itimindex + 1 < modifiergroup.choices.length">, </span>
                                                                </span>
                                                              </div>
                                                              <div v-else>
                                                                <span>-</span>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="modal-footer">
                                                <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-default custom-orange" @click="AddFmodifiers()">Save</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!--  -->
                                        <!-- end modifier group-->
                                        <div class="item_modfier mt-3">
                                          <div v-for="(fremod, pmodindex) in freeModifiers.filter((y) => y.item_id === product.menu_item.id && y.parent_id === item.menu_item.id)" :key="fremod.mod_id" class="modifier_item_list" draggable="true" @drop="onModDrop($event,pmodindex,product.menu_item.id,item.menu_item.id)" @dragenter.prevent @dragover.prevent @dragstart="startModDrag($event,pmodindex)" style="cursor:pointer;">
                                            <p>
                                              <span data-toggle="collapse" :data-target="`#modifier_${fremod.mod_id}_${product.menu_item.id}_${item.menu_item.id}`" aria-expanded="false" aria-controls="collapseExample" class="mb-3"> <b>{{fremod.mod_name}} &nbsp;&nbsp;</b> <i class="float-right fa fa-trash-o" @click="removeFreeItemModifier(product.menu_item.id,item.menu_item.id,fremod.mod_id)"></i> <i class='float-right far fa-edit'></i></span>
                                            </p>
                                            <div class="collapse" :id="`modifier_${fremod.mod_id}_${product.menu_item.id}_${item.menu_item.id}`">
                                              <div class="row" v-for="itm  in fremod.items" :key="itm.id">

                                                <div class="col-md-2">
                                                  <span>
                                                    {{ itm.name }}
                                                  </span>
                                                </div>
                                                <div class="col-md-5">
                                                  <label>Pos ID</label>
                                                  <div v-for="config in posConfigs" :key="config.id">

                                                    <div class="form-group">
                                                      <label for="code">{{ config.name }} <span v-if="config.version"><small>({{ config.version }})</small></span>:</label>
                                                      <input class="offer_pos_code" v-model="groupItemPos[`${item.menu_item.id}_${product.menu_item.id}_${itm.id}_${config.id}_${fremod.mod_id}`]">
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-md-4">

                                                  <div class="form-group">
                                                    <label for="price">Price</label>
                                                    <input class="offer_pos_code" v-model="groupItemPrice[`${item.menu_item.id}_${product.menu_item.id}_${itm.id}`]">
                                                  </div>
                                                </div>
                                                <div class="col-md-1">
                                                  <span class='float-right'><i class='fa fa-trash-o' @click="removeModifierFreeItem(item.menu_item.id,product.menu_item.id,fremod.mod_id,itm.id)"></i></span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                              <!-- free items -->
                            </div>
                          </div>
                          <!--here-->

                        </div>
                      </div>
                      <div class="free_items_row mt-2" v-if="freeProducts.length > 0">
                        <span v-if="offerType == 1"><strong>Free Products (drag to primary product to attach the two products)</strong></span>
                        <div v-for="item in freeProducts" :key="item.menu_item.id" class="menu_item_list" draggable="true" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,item)">
                          <!-- <div class=""> -->
                          <div class="p_item_image mr-1">
                            <i class="fa fa-arrows-alt"></i>
                          </div>
                          <div class="p_item_desc">
                            <h2> {{item.menu_item.item_name}}</h2>
                            <div>
                              <span class="mr-4 strike_text">{{currency+" "+item.menu_item.price}}</span>
                              <!-- <span style="color:#333333">Code:</span> <input type="text" class="offer_pos_code"> -->
                            </div>
                          </div>
                          <div class="p_remove">
                            <div>
                              <span @click="removeFreeProduct(item.menu_item.id)"><i class='fa fa-trash-o'></i></span>
                            </div>
                          </div>
                          <!-- </div> -->

                        </div>
                      </div>
                    </div>

                    <div id="foodie-loading-cover" class="pl-4 pr-4 pt-4 mb-4">
                      <h2 class="section-title">Offer Banner</h2>
                      <div data-baseweb="form-control">
                        <div class="image_div">
                          <div class="img_preview" style="">
                            <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                              <img class="image_responsive" v-if="imageUrls && imageUrls.length > 0" :src="imageUrls[imageindex]" />
                            </div>

                          </div>
                          <div class="image_content">
                            <p>Upload your offer banner (Dimension 2484 X 1380).</p>

                            <div class="image_button">
                              <div v-if="imageUrls && imageUrls.length > 0" :class="['add_image_btn_grp', {'item_btn_add_grp': imageUrls.length > 0}, 'mt-1']">
                                <div class="btn_plus_icon">
                                  <i class="fa fa-minus" style="font-size:24px" @click="removeCatImage()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Remove Image</span>
                                </div>
                              </div>

                              <div :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-2']">
                                <div class="btn_plus_icon">
                                  <input id='fileid' type='file' @change="uploadBanner($event,2484 ,1380)" hidden />
                                  <i class="fa fa-plus" style="font-size:24px" @click="openFileUploadDialog()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Add from URL</span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="row mt-4" v-if="imageUrls && imageUrls.length > 1">
                          <div class="col-md-3" v-for="(img,imgindex) in imageUrls" :key="imgindex" @click="changeImage(imgindex)">
                            <img class="image_responsive" :src="img" />
                            <!-- <div
                              class="col-md-3 mr-1 contain_image_section"
                              v-for="(img,imgindex) in imageUrls" :key="imgindex"
                              :style="{'background-image': 'url(' + imageUrls[imgindex] + ')'}"
                            >
                            </div> -->
                          </div>
                        </div>
                      </div>

                      <div id="foodie-loader"> </div>
                    </div>

                  </div>
                </div>

                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-5 mt-3">
                      <button type="submit" class="btn btn-orange mr-3">
                        <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Pagination from '@/pages/pagination/pagination.vue';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '../../../../mixins/ReusableFunctionMixins';
// import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
const toast = useToast();

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';
// import { parse } from 'date-fns/esm';
// import { ref } from 'vue';

export default {
    props: ['token'],
    components: {
        // Multiselect
        Pagination,
        Editor
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            startTime:{ hours: 0, minutes: 0 },
            endTime:{ hours: 23, minutes: 59 },
            productError: false,
            freeProductError: false,
            itemType: 'same',
            search: '',
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> creating...",
            accompaniments: true,
            itemSelected: false,
            selectItemsCount: 0,
            selectFreeItemsCount: 0,
            scheduleTime: false,
            productReturnPrice: false,
            title: '',
            itemSearch: '',
            itemLoading: false,
            catSearch: '',
            spinner: 0,
            channels: [],
            currency: 'KES',
            categories: [],
            scroll: 1,
            items: [],
            itemPage: 1,
            totalPages: 0,
            itemsize: 20,
            totalRecords: 0,
            catPage: 1,
            catTotalPages: 0,
            catsize: 20,
            catTotalRecords: 0,
            discountVal: 0,
            discountType: 1,
            offerType: '',
            selectedItems: [],
            selectedItemsId: [],
            childItems: [],
            checkedChannel: {},
            freeItemIds: [],
            freeItems: [],
            startDate: '',
            endDate: '',
            freeModifiers: [],
            products: [], // this.$store.state.products,
            freeProducts: [], // this.$store.state.freeProducts,
            productFreeItem: {}, //this.$store.state.productFreeItem,
            modifiersGroups: [],
            imageUrl: '',
            images: null,
            disableSelected: false,
            catLoading: false,
            catselected: false,
            selectedcatItems: [],
            allChannelSelected: true,
            selecteditemsModifier: [],
            selectedFreeItemModifiers: [],
            modifiers: [], // this.$store.state.modifiers,
            categoyItems: [],
            generateItemTag: [],
            itemPos: [],
            itemPrice: [],
            itemPriceOverride: [],
            debounce: null,
            groupItemPos: [],
            groupItemPrice: [],
            recurrence: false,
            bogof: false,
            recurring: 0,
            selectedDays: [],
            overrideCent: false,
            roundingType: 0,
            roundingVal: '',
            imageUrls: [],
            imageindex: 0,
            generateCategory: true,
            generateTag: true,
            parrentOffer: '',
            description:'',
            offers: [],
            days: [{
                    'key': 1,
                    'value': 'Monday'
                },
                {
                    'key': 2,
                    'value': 'Tuesday'
                },
                {
                    'key': 3,
                    'value': 'Wednesday'
                },
                {
                    'key': 4,
                    'value': 'Thursday'
                },
                {
                    'key': 5,
                    'value': 'Friday'
                },
                {
                    'key': 6,
                    'value': 'Saturday'
                },
                {
                    'key': 0,
                    'value': 'Saturday'
                }
            ],
            mainCategories:[],
            offerCategory: '',
            applicableTo:1,
            Itemquantity: 1,
            quantityType: '1',
            orderType: 2,
            valueAbove: 0,
            posConfigs:[],
            stores:[],
            includedBranches:[],
            allselected:false,
            branchSelected: false,
            offerTags: [],
            boltTags: [
                  'special_offer_5',
                  'special_offer_10',
                  'special_offer_15',
                  'special_offer_20',
                  'special_offer_25',
                  'special_offer_30',
                  'special_offer_35',
                  'special_offer_40',
                  'special_offer_45',
                  'special_offer_50'
          ],
          uberTags :[],
          jumiaTags:[],
          glovoTags:[],
          selecteItemTag:[],
          discountCode:[],
          FreeItemdiscountCode:[],
          tagSelected: {},
        }
    },
    validations() {
        return {
            title: { required },
            startDate: { required },
            endDate: { required },
        }
    },
    watch: {
        itemType() {
            if (this.itemType !== 'same') {
                this.scroll = 2;
                this.disableSelected = true;
            } else {
                this.scroll = 1;
                this.disableSelected = false;
            }
        },
        recurrence() {
            this.recurring = this.recurrence ? 1 : 0;
        },
        bogof() {
            this.offerType = this.bogof ? 1 : '';
        },
        applicableTo(){
          if(this.applicableTo == 2){
            this.allChannelSelected = false
            this.checkedChannel = { 0: true  }
          }else{
            this.allChannelSelected = true
          }
        },
        allChannelSelected() {
            if(this.allChannelSelected) {
                this.checkedChannel = {}
            }
        }
    },
    computed: {
      otherChannelSelected() {
          return Object.values(this.checkedChannel).some(x => x)
      },
    },
    mounted() {
        this.currency = this.pesapalConfig.currency_code ? this.pesapalConfig.currency_code : 'KES'
        this.getBranches();
        this.getThirdParties();
        this.getItems();
        this.getCategories();
        this.getModifiers();
        this.getOffers();
        this.getMainCategories()
        this.getPosConfigs()
    },

    methods: {
      itemStatusName(item) {
        switch(item.status) {
          case 1:
            return "Published"
          case 2:
            return "Unpublished"
          case 3:
            return "Archived"
          case 4:
            return "Trashed"
          default:
            return "Unknown"
        }
      },
      itemStatusStyles(item) {
        const isVisible = item.status === 1
        return {
          'background-color': isVisible ? '#FCF2EF' : '#FF4F22',
          'border-radius': '20px',
          //'background': '#EEDDD9',
          'display': 'inline-block',
          'font-weight': 400,
          'font-size': '14px',
          'line-height': '16px',
          'color': isVisible ? '#333333' : '#FFFFFF',
          'padding': '7px 15px',
          'margin': '0 5px',
        }
      },
      addSelectedTag(itemId,channel,tag){
        const currentTags = this.selecteItemTag.filter(it => {
              if(it.item_id !== itemId || it.channel !== channel.channel_name) {
                return true;
              }

              const currentlySelected = it.offer_tag === tag
              this.tagSelected[itemId+channel.channel_name+it.offer_tag] = currentlySelected;

              return currentlySelected;
            })
            const alreadyAdded = currentTags.some((x) => {
              return x.item_id == itemId && x.offer_tag == tag && x.channel == channel.channel_name
            })
            if(!alreadyAdded) {
                  currentTags.push({
                      'item_id': itemId,
                      'offer_tag': tag,
                      'channel': channel.channel_name
                  })
            }
            this.selecteItemTag  = currentTags;
      },
      addTags(channel){
        if(document.getElementById(`channel_${channel.channel_name}`).checked){
          let selectedTags = []
          switch(channel.channel_name) {
            case 'BOLT':
              selectedTags = this.boltTags
              break;
            case 'UBER':
              selectedTags = this.uberTags
              break;
            case 'JUMIA':
              selectedTags = this.jumiaTags
              break;
            case 'GLOVO':
              selectedTags = this.glovoTags
              break;
            default:
              selectedTags = []
          }
          // notify if no tags is set yet
          if(selectedTags.length == 0){
            toast.error("no offer tags is set for channel "+ channel.channel_name)
            document.getElementById(`channel_${channel.channel_name}`).checked = false
          }
          this.offerTags[channel.channel_name] = selectedTags
        }else{
          this.offerTags[channel.channel_name] =[]
          this.selecteItemTag = this.selecteItemTag.filter((x) => x.channel != channel.channel_name)
        }
      },
      addedBranches(branchID){
          if(branchID == 'all'){
            this.branchSelected = false;
            if(document.getElementById('all_stores').checked){
              this.allselected = true
              this.includedBranches = [];
            }else{
              this.allselected = false
            }
          }else{
            this.allselected = false
            if(document.getElementById(`selected_store_${branchID}`).checked){
              this.branchSelected = true
              const selectedBranches = this.includedBranches.filter((x) => x === branchID)
              if(selectedBranches.length === 0){
                this.includedBranches.push(branchID)
              }
            }else{
              this.includedBranches =  this.includedBranches.filter((x) => x !=branchID)
              if(this.includedBranches.length == 0){
                this.branchSelected = false;
              }
            }
          }

        },
        getBranches() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "//branch?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        this.stores = response.data.data;
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })

        },
      async getPosConfigs() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchant-pos-config/${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.posConfigs = response.data.data
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
            //
      },
      addTagOnItem(itemId){
        if(document.getElementById(`item_tag_${itemId}`).checked){
          this.generateItemTag[itemId] =true;
        }else{
          this.generateItemTag[itemId] = false;
        }
      },
      getMainCategories() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "//menu_category?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.mainCategories = response.data.data;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        },
        changeImage(index) {
            this.imageindex = index;
        },
        removeCatImage() {
            this.imageUrls = this.imageUrls.filter((x) => {
                return x != this.imageUrls[this.imageindex];
            });
            this.imageindex = 0;
        },
        addDays(day) {
            if(document.getElementById(`days_of_work${day}`).checked){
              const selectedDays = this.selectedDays.filter((x) => x === day);
              if (selectedDays.length === 0) {
                  this.selectedDays.push(day)
              }
            }else{
              this.selectedDays = this.selectedDays.filter((x) => x != day);
            }
        },
        AddFmodifiers() {
            let modGrp = [];
            let moditem = [];
            for (const mod of Object.values(this.selectedFreeItemModifiers)) {
                if (document.getElementById(`free_mod_${mod.id}_${mod.item_id}_${mod.parent_id}`) && document.getElementById(`free_mod_${mod.id}_${mod.item_id}_${mod.parent_id}`).checked) {
                    document.getElementById(`free_mod_${mod.id}_${mod.item_id}_${mod.parent_id}`).checked = false;
                }
                const moditems = [];
                for (const item of Object.values(mod.choices)) {
                    moditem = moditems.filter((x) => x.id === item.id)
                    if (moditem.length === 0) {
                        moditems.push({
                            'id': item.id,
                            'name': item.item_name,
                            'pos_id': item.pos_id,
                            'price': item.price
                        })
                    }
                    const itemPoscodes = item.menu_item_pos
                    if(itemPoscodes != undefined && itemPoscodes.length > 0){
                      for(const itemPos of Object.values(itemPoscodes)){
                        this.groupItemPos[`${mod.parent_id}_${mod.item_id}_${item.id}_${itemPos.pos_systems}_${mod.id}`] = itemPos.pos_id
                      }
                    }
                }
                modGrp = this.freeModifiers.filter((x) => x.mod_id === mod.id && x.item_id === mod.item_id && x.parent_id === mod.parent_id)
                if (modGrp.length === 0) {
                    this.freeModifiers.push({
                        'mod_id': mod.id,
                        'item_id': mod.item_id,
                        'parent_id': mod.parent_id,
                        'mod_name': mod.name,
                        'items': moditems
                    })
                }

            }
        },
        addFreeItemModifier(mod, itemId, parentId) {
            const itemMod = {
                'id': mod.id,
                'item_id': itemId,
                'parent_id': parentId,
                'name': mod.name,
                'choices': mod.choices
            }
            if (document.getElementById(`free_mod_${mod.id}_${itemId}_${parentId}`).checked) {
                const selectedModLen = this.selectedFreeItemModifiers.filter((x) => x.id === mod.id && x.item_id === itemId && x.parent_id == parentId)
                if (selectedModLen.length == 0) {
                    this.selectedFreeItemModifiers.push(itemMod)
                }
            } else {
                this.selectedFreeItemModifiers = this.selectedFreeItemModifiers.filter((x) => x.id !== mod.id && x.item_id !== itemId && x.parent_id !== parentId)
            }
        },
        addItemMofiers(mod, itemId) {
            const itemMod = {
                'id': mod.id,
                'item_id': itemId,
                'name': mod.name,
                'choices': mod.choices
            }
            if (document.getElementById("mod_" + mod.id + itemId).checked) {
                const selectedMod = this.selecteditemsModifier.filter((x) => x.id === mod.id && x.item_id === itemId)
                if (selectedMod.length == 0) {
                    this.selecteditemsModifier.push(itemMod)
                }
            } else {
                this.selecteditemsModifier = this.selecteditemsModifier.filter((x) => x.id !== mod.id && x.item_id !== itemId)
            }
        },
        addItemsModifiers(itemId) {
            let modGrp = [];
            let moditem = [];
            for (const mod of Object.values(this.selecteditemsModifier)) {
                if(document.getElementById(`mod_${mod.id}${itemId}`)){
                  document.getElementById(`mod_${mod.id}${itemId}`).checked = false;
                }
                const moditems = [];
                for (const item of Object.values(mod.choices)) {
                    moditem = moditems.filter((x) => x.id === item.id)
                    if (moditem.length === 0) {
                        moditems.push({
                            'id': item.id,
                            'name': item.item_name,
                            'pos_id': item.pos_id,
                            'price': item.price
                        })
                    }
                    this.groupItemPrice[`${mod.item_id}_${item.id}`] = item.price
                    const itemPoscodes = item.menu_item_pos
                    if(itemPoscodes != undefined && itemPoscodes.length > 0){
                      for(const itemPos of Object.values(itemPoscodes)){
                        this.groupItemPos[`${mod.item_id}_${item.id}_${itemPos.pos_systems}_${mod.id}`] = itemPos.pos_id
                      }
                    }
                }
                modGrp = this.modifiers.filter((x) => x.mod_id === mod.id && x.item_id === itemId)
                if (modGrp.length === 0) {
                    this.modifiers.push({
                        'mod_id': mod.id,
                        'item_id': itemId,
                        'mod_name': mod.name,
                        'items': moditems
                    })
                }

            }
        },
        removingModifier(parentId, modId) {
            const len = this.modifiers.length;
            let i = 0;
            while (i < len) {
                if (this.modifiers[i].mod_id === modId && this.modifiers[i].item_id == parentId) {
                    this.modifiers.splice(i, 1)
                    break;
                }
                i++
            }
        },
        async getOffers() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.baseUrl}/offers`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const response = await this.axios.get(url, config)
            if (response.status === 200) {
                this.offers = response.data.data;
            }

        },
        searchModifiers() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getModifiers();
            }, 600)
        },
        async getModifiers() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/modifier_group?merchant_id=" + this.merchantID + "&query=" + this.search;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.modifiersGroups = response.data.data;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
        },
        removeFreeItemModifier(itemId, parentId, modId) {
            const len = this.freeModifiers.length;
            let i = 0;
            while (i < len) {
                if (this.freeModifiers[i].mod_id === modId && this.freeModifiers[i].item_id == itemId && this.freeModifiers[i].parent_id == parentId) {
                    this.freeModifiers.splice(i, 1)
                    break;
                }
                i++
            }
        },
        removeModifierItem(parentId, modId, childId) {
            const modifiers = this.modifiers.filter((x) => x.mod_id === modId && x.item_id === parentId)
            let modItems = [];
            if (modifiers.length > 0) {
                modItems = modifiers[0].items.filter((x) => x.id !== childId)
                if (modItems.length == 0) {
                    this.removingModifier(parentId, modId)
                }
                const modObj = {
                    'mod_id': modifiers[0].mod_id,
                    'item_id': modifiers[0].item_id,
                    'mod_name': modifiers[0].mod_name,
                    'items': modItems
                }
                // section to remove object and replace at specific index
                const len = this.modifiers.length;
                let i = 0;
                while (i < len) {
                    if (this.modifiers[i].mod_id === modId && this.modifiers[i].item_id == parentId) {
                        this.modifiers.splice(i, 1)
                        this.modifiers.splice(i, 0, modObj)
                        break;
                    }
                    i++
                }
            }
        },
        removeModifierFreeItem(parentId, itemId, modId, childId) {
            const modifiers = this.freeModifiers.filter((x) => x.mod_id === modId && x.item_id === itemId && x.parent_id === parentId)
            let modItems = [];
            if (modifiers.length > 0) {
                modItems = modifiers[0].items.filter((x) => x.id !== childId)
                if (modItems.length == 0) {
                    this.removeFreeItemModifier(itemId, parentId, modId)
                }
                const modObj = {
                    'mod_id': modifiers[0].mod_id,
                    'item_id': modifiers[0].item_id,
                    'mod_name': modifiers[0].mod_name,
                    'parent_id': modifiers[0].parent_id,
                    'items': modItems
                }
                // // section to remove object and replace at specific index
                const len = this.freeModifiers.length;
                let i = 0;
                while (i < len) {
                    if (this.freeModifiers[i].mod_id === modId && this.freeModifiers[i].item_id == itemId && this.freeModifiers[i].parent_id === parentId) {
                        this.freeModifiers.splice(i, 1)
                        this.freeModifiers.splice(i, 0, modObj)
                        break;
                    }
                    i++
                }
            }
        },
        removeProducts() {
            this.selectedItemsId = [];
            this.selectedItems = [];
            this.products = this.selectedItems;
            this.freeProducts = [];
        },
        async uploadBanner(event, width, height, type = '') {
            // initiating loader
            const imageLoader = document.getElementById('foodie-loading-cover');
            imageLoader.style.opacity = .4;
            const loader = document.getElementById('foodie-loader');
            loader.style.display = 'block';
            const files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                toast.error("file too large");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return;
            }
            const file = files[0];

            const allowedExtensions =
                /(\.jpg|\.jpeg|\.jpe|\.svg|\.png|\.gif|\.WEBP)$/i;
            // end typ
            const fileName = file.name
            if (!allowedExtensions.exec(fileName)) {
                toast.error("cannot support the file type please use jpg,jpeg,png,gif or WEBP");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return false;
            }

            const imageSize = file.size / 1000000
            if (type === 'item' && imageSize > 1) {
                toast.error("file too large")
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return
            }
            const reader = new FileReader();
            // Wait for the data url to be loaded from the file
            const dataURL = await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
            // wait for the image to be loaded
            const img = new Image();

            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });
            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            let nWidth = imgWidth
            let nHeight = imgHeight
            if (imgWidth > width) {
                const ratio = width / imgWidth;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            if (imgHeight > height) {
                const ratio = height / imgHeight;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            canvas.width = nWidth;
            canvas.height = nHeight;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const images = canvas.toDataURL('image/jpeg', 0.9);

            const binary = atob(images.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

            const formData = new FormData();

            formData.append('image_file', blobData);
            const config = this.apiRequestConfig({
                'content-type': 'multipart/form-data'
            });
            const url = `${this.baseUrl}/add_image`;
            this.axios.post(url, formData, config).then((response) => {
                    if (response.status === 200) {
                        // this.url = images;
                        this.imageUrls.push(response.data.image_url)
                        this.images = images;
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
        },
        getnewPrice(price) {
            let newPrice = price;
            if (this.offerType === 3 && parseInt(this.discountVal) > 0) {
                const percRate = parseFloat(this.discountVal) / 100
                const val = parseFloat(price) * percRate
                newPrice = parseFloat(price) - val;
            } else {
                newPrice = parseFloat(price) - this.discountVal;
            }
            return newPrice;
        },
        containsObject(obj, list) {
            let i;
            for (i = 0; i < list.length; i++) {
                // check for modifiers
                const itemMod = this.freeModifiers.filter((x) => x.item_id === obj.menu_item.id)
                if (itemMod.length == 0 && list.length < 5) {
                    if (list[i] === obj) {
                        return true;
                    }
                } else if (itemMod.length > 0 && list.length < 2) {
                    if (list[i] === obj) {
                        return true;
                    }
                }

            }

            return false;
        },
        freeItemExist(attachedItems,item){
          let exist = false;
          for(let i=0; i < attachedItems.length; i++){
            if(attachedItems[i].menu_item.id == item.menu_item.id){
              exist = true;
              break;
            }
          }
          return exist
        },
        onDrop(event, item) {
            const productId = event.dataTransfer.getData('free_item_id')
            const freeproduct = this.freeProducts.filter((x) => x.menu_item.id === productId);
            if (this.productFreeItem[item.menu_item.id]) {
                if(!this.freeItemExist(this.productFreeItem[item.menu_item.id],freeproduct[0])){
                    this.productFreeItem[item.menu_item.id].push(freeproduct[0]);
                }
            } else {
                const products = [];
                products.push(freeproduct[0]);
                this.productFreeItem[item.menu_item.id] = products
            }
            this.constructFreeModfiers(this.productFreeItem[item.menu_item.id], item.menu_item.id)
        },
        startDrag(event, item) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('free_item_id', item.menu_item.id);
        },
        removeProduct(id) {
            const len = this.products.length;
            let i = 0;
            while (i < len) {
                if (this.products[i].menu_item.id === id) {
                    this.products.splice(i, 1);
                    break;
                }
                i++
            }
            // this.products = this.selectedItems;
            this.productFreeItem[id] = [];
        },
        removeAttachedItem(pid, id) {
            this.productFreeItem[pid] = this.productFreeItem[pid].filter((x) => x.menu_item.id !== id)
        },
        removeFreeProduct(id) {
            this.freeItems = this.freeItems.filter((x) => x.menu_item.id !== id);
            this.freeProducts = this.freeItems;
        },
        constructFreeModfiers(products,parentItemId) {
            let modGrp = [];
            let moditem = [];
            for (const product of Object.values(products)) {
                  const modPoscodes = product.mod_menu_item_pos
                  if(modPoscodes != undefined){
                    for(let mo=0; mo < modPoscodes.length; mo++){
                      this.groupItemPos[`${parentItemId}_${modPoscodes[mo].parent_item_id}_${modPoscodes[mo].item_id}_${modPoscodes[mo].pos_systems}_${modPoscodes[mo].mod_group_id}`]= modPoscodes[mo].pos_id
                    }
                  }
                if (product.mod_groups.length > 0) {

                    for (const mod of Object.values(product.mod_groups)) {
                        const moditems = [];
                        for (const item of Object.values(mod.items)) {
                            moditem = moditems.filter((x) => x === item.id)
                            if (moditem.length === 0) {
                                moditems.push({
                                    'id': item.id,
                                    'name': item.name,
                                    'pos_id': item.pos_id,
                                    'price': item.price
                                })
                            }
                        }
                        modGrp = this.freeModifiers.filter((x) => x.mod_id === mod.mod_group_id && x.item_id ===  product.menu_item.id)
                        if (modGrp.length === 0) {
                            this.freeModifiers.push({
                                'mod_id': mod.mod_group_id,
                                'item_id': product.menu_item.id,
                                'parent_id': parentItemId,
                                'mod_name': mod.mod_group,
                                'items': moditems
                            })
                        }
                    }
                }
            }
        },
        constructModifiers(products) {
            let modGrp = [];
            let moditem = [];
            for (const product of Object.values(products)) {
                if (product.mod_groups.length > 0) {
                    for (const mod of Object.values(product.mod_groups)) {
                        const moditems = [];
                        for (const item of Object.values(mod.items)) {
                            moditem = moditems.filter((x) => x === item.id)
                            if (moditem.length === 0) {
                                moditems.push({
                                    'id': item.id,
                                    'name': item.name,
                                    'pos_id': item.pos_id,
                                    'price': item.price
                                })
                            }
                        }
                        modGrp = this.modifiers.filter((x) => x.mod_id === mod.mod_group_id && x.item_id === product.menu_item.id)
                        if (modGrp.length === 0) {
                            this.modifiers.push({
                                'mod_id': mod.mod_group_id,
                                'item_id': product.menu_item.id,
                                'mod_name': mod.mod_group,
                                'items': moditems
                            })
                        }

                    }
                }
            }
        },
        addSelectedItems() {
            this.constructModifiers(this.products)
            for (let i = 0; i < this.products.length; i++) {
                if (document.getElementById(`item_${this.products[i].menu_item.id}`) && document.getElementById(`item_${this.products[i].menu_item.id}`).checked) {
                    document.getElementById(`item_${this.products[i].menu_item.id}`).checked = false;
                }
                // fill poscodes
                const itemPosIds = this.products[i].menu_item_pos
                for(let po=0; po < itemPosIds.length; po++){
                  this.itemPos[`${this.products[i].menu_item.id}_${itemPosIds[po].pos_systems}`] = itemPosIds[po].pos_id
                }
                // filling modposcodes
                const modPoscodes = this.products[i].mod_menu_item_pos
                for(let mo=0; mo < modPoscodes.length; mo++){
                  this.groupItemPos[`${modPoscodes[mo].parent_item_id}_${modPoscodes[mo].item_id}_${modPoscodes[mo].pos_systems}_${modPoscodes[mo].mod_group_id}`] = modPoscodes[mo].pos_id
                }
                // ${item.menu_item.id}_${product.menu_item.id}_${config.id}
                // this.itemPrice[this.products[i].menu_item.id] = this.products[i].menu_item.price
                const modifiers = this.modifiers.filter((x) => x.item_id == this.products[i].menu_item.id)
                for (const mod of Object.values(modifiers)) {
                    for (const moditem of Object.values(mod.items)) {
                        // this.groupItemPos[this.products[i].menu_item.id + '_' + moditem.id] = moditem.pos_id;
                        this.groupItemPrice[this.products[i].menu_item.id + '_' + moditem.id] = moditem.price;
                    }
                }
                // if free item is same as original
                if (this.itemType == 'same' && this.offerType == 1) {
                    const freeproduct = this.products[i];
                    if (!this.productFreeItem[this.products[i].menu_item.id]) {
                      const products = [];
                      products.push(freeproduct);
                      this.productFreeItem[this.products[i].menu_item.id] = products
                    }
                    this.constructFreeModfiers(this.productFreeItem[this.products[i].menu_item.id],this.products[i].menu_item.id);
                }
            }
            //
            this.freeProducts = this.freeItems;
            // sa
            for (let fr = 0; fr < this.freeProducts.length; fr++) {
                if (document.getElementById(`item_${this.freeProducts[fr].menu_item.id}`) && document.getElementById(`item_${this.freeProducts[fr].menu_item.id}`).checked) {
                    document.getElementById(`item_${this.freeProducts[fr].menu_item.id}`).checked = false;
                }
                // this.itemPos[this.freeProducts[fr].menu_item.id] = this.freeProducts[fr].metadata.pos_id
                this.itemPrice[this.freeProducts[fr].menu_item.id] = this.freeProducts[fr].menu_item.price
                const modifiers = this.modifiers.filter((x) => x.item_id == this.freeProducts[fr].menu_item.id)
                for (const mod of Object.values(modifiers)) {
                    for (const moditem of Object.values(mod.items)) {
                        // this.groupItemPos[this.freeProducts[fr].menu_item.id + '_' + moditem.id] = moditem.pos_id;
                        this.groupItemPrice[this.freeProducts[fr].menu_item.id + '_' + moditem.id] = moditem.price;
                    }
                }
            }
            this.scroll = 1;
            this.itemType = 'same';
            this.selectItemsCount = 0;
            this.selectFreeItemsCount = 0;
            this.selectedItemsId = [];
            document.getElementById('productM_close').click();
        },
        addCatItems() {
            // this.catProducts = this.selectedcatItems
            // this.selectedItemsId = [];
            // this.selectedItems = [];
            // this.products = this.selectedItems;
            // this.freeProducts = [];
            document.getElementById('catprod_close').click();
        },
        selectCategories(cat) {
            if (document.getElementById('cat_' + cat.id).checked) {
                this.catselected = cat.id;
                for (let p = 0; p < cat.items.length; p++) {
                    // constructing modifiers
                    const modifiers = cat.items[p].mod_groups
                    for (let j = 0; j < modifiers.length; j++) {
                        const _modItems = [];
                        for (let x = 0; x < modifiers[j].items.length; x++) {
                            _modItems.push({
                                'id': modifiers[j].items[x].id,
                                'name': modifiers[j].items[x].name,
                                'pos_id': modifiers[j].items[x].pos_id,
                                'price': modifiers[j].items[x].price
                            })
                        }
                        this.modifiers.push({
                            'mod_id': modifiers[j].mod_group_id,
                            'item_id': cat.items[p].menu_item.id,
                            'mod_name': modifiers[j].mod_group,
                            'items': _modItems
                        })
                    }
                    // end
                    const _product = {
                        "menu_item": {
                            "id": cat.items[p].menu_item.id,
                            "item_name": cat.items[p].menu_item.item_name,
                            "price": cat.items[p].menu_item.price,
                            "image": cat.items[p].menu_item.image_url,
                            "status": cat.items[p].menu_item.status,
                        },
                        "metadata": {
                            'pos_id': cat.items[p].metadata.pos_id
                        },
                        "mod_groups": []
                    }
                    this.products.push(_product)

                    //
                    for (let i = 0; i < this.products.length; i++) {
                        this.itemPos[this.products[i].menu_item.id] = this.products[i].metadata.pos_id
                        this.itemPrice[this.products[i].menu_item.id] = this.products[i].menu_item.price
                        const modifiers = this.modifiers.filter((x) => x.item_id == this.products[i].menu_item.id)
                        for (const mod of Object.values(modifiers)) {
                            for (const moditem of Object.values(mod.items)) {
                                this.groupItemPos[this.products[i].menu_item.id + '_' + moditem.id] = moditem.pos_id;
                                this.groupItemPrice[this.products[i].menu_item.id + '_' + moditem.id] = moditem.price;
                            }
                        }

                    }
                    //
                }

            } else {
                this.catselected = false
            }
        },
        selectItems(item) {
            if (document.getElementById(`item_${item.menu_item.id}`).checked) {
                if (this.scroll === 1) {
                    if (this.selectedItemsId.indexOf(item.menu_item.id) === -1) {
                        this.selectedItemsId.push(item.menu_item.id)
                        this.products.push(item)
                    }
                } else {
                    if (this.freeItemIds.indexOf(item.menu_item.id) === -1) {
                        this.freeItemIds.push(item.menu_item.id)
                        this.freeItems.push(item)
                    }
                }

            } else {
                if (this.scroll === 1) {
                    if (this.selectedItemsId.indexOf(item.menu_item.id) !== -1) {
                        this.selectedItemsId = this.selectedItemsId.filter((x) => x !== item.menu_item.id);
                        this.products = this.products.filter((x) => x.menu_item.id !== item.menu_item.id)
                    }
                } else {
                    if (this.freeItemIds.indexOf(item.menu_item.id) !== -1) {
                        this.freeItemIds = this.selectedItemsId.filter((x) => x !== item.menu_item.id);
                        this.freeItems = this.selectedItems.filter((x) => x.menu_item.id !== item.menu_item.id)
                    }
                }
            }
            this.selectItemsCount = this.products.length
            this.selectFreeItemsCount = this.freeItems.length
            this.itemSelected = this.selectItemsCount > 0 ? true : false
        },
        searchCategories() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getCategories();
            }, 600)
        },
        searchItems() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getItems();
            }, 600)
        },
        customSDateFormatter(date) {
            this.startDate = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
            return moment(date).format('YYYY-MM-DD h:mm:ss');
        },
        customEDateFormatter(date) {
            this.endDate = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
            return moment(date).format('YYYY-MM-DD h:mm:ss');
        },
        // customfSDateFormatter(date) {
        //     // this.fstartDateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
        //     // return moment(date).format('YYYY-MM-DD h:mm:ss');
        // },
        customfEDateFormatter(date) {
            this.fendDateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
            return moment(date).format('YYYY-MM-DD h:mm:ss');
        },
        onItemPageChange(page) {
            this.itemPage = page
            this.getItems();
        },
        onCatPageChange(page) {
            this.catPage = page
            this.getCategories();
        },
        async createOffer() {
            this.spinner = 1;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                toast.error("please fill all input fields")
                this.spinner = 0;
                return
            } else {
                //  validating product and free products
                for (const product of Object.values(this.products)) {
                  for (let x = 0; x < this.posConfigs.length; x++) {
                    if (this.itemPos[`${product.menu_item.id}_${this.posConfigs[x].id}`] == undefined || this.itemPos[`${product.menu_item.id}_${this.posConfigs[x].id}`] == '') {
                          this.productError = product.menu_item.id;
                          this.spinner = 0
                          toast.error("please fill all input fields")
                          return;
                      }
                    }
                    if (this.productFreeItem[product.menu_item.id] != undefined && this.productFreeItem[product.menu_item.id].length > 0) {
                        for (const freeProduct of Object.values(this.productFreeItem[product.menu_item.id])) {
                          for (let x = 0; x < this.posConfigs.length; x++) {
                              if (this.itemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${this.posConfigs[x].id}`] == undefined || this.itemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${this.posConfigs[x].id}`] == '') {
                                  this.freeProductError = freeProduct.menu_item.id;
                                  this.spinner = 0
                                  toast.error("please fill all input fields")
                                  return;
                              }
                            }
                        }
                    }
                }
                const items = []
                const itemModPosCodes = []
                const itemPesapalPos = [];
                const freeitemPesapalPos = [];
                const modposItems = [];
                for (const product of Object.values(this.products)) {
                    const freeItems = [];
                    if (this.productFreeItem[product.menu_item.id] != undefined) {
                        for (const freeProduct of Object.values(this.productFreeItem[product.menu_item.id])) {
                            for (let x = 0; x < this.posConfigs.length; x++) {
                              freeitemPesapalPos.push({
                                    'pos_id':this.itemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${this.posConfigs[x].id}`],
                                    'pos_systems': this.posConfigs[x].id,
                                    'item_id': freeProduct.menu_item.id,
                                    'offer_parent_item_id':product.menu_item.id,
                                    'discount_code':this.FreeItemdiscountCode[`${product.menu_item.id}_${freeProduct.menu_item.id}`]
                                })
                            }
                            const fmodifiers = this.freeModifiers.filter((y) => y.item_id === freeProduct.menu_item.id && y.parent_id === product.menu_item.id)
                            const faddons = [];

                            for (const fmodifier of Object.values(fmodifiers)) {
                                for (const fmodItem of Object.values(fmodifier.items)) {
                                  for(let px =0; px <  this.posConfigs.length; px++ ){
                                     const modItemPos =  this.groupItemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${fmodItem.id}_${this.posConfigs[px].id}_${fmodifier.mod_id}`]//  this.groupItemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${fmodItem.id}_${this.posConfigs[px].id}`]
                                      modposItems.push({
                                        'pos_id':modItemPos,
                                        'pos_systems':this.posConfigs[px].id,
                                        'mod_group_id':fmodifier.mod_id,
                                        'item_id':fmodItem.id,
                                        'parent_item_id':freeProduct.menu_item.id,
                                        'offer_parent_item_id':product.menu_item.id
                                      })
                                    }
                                    faddons.push({
                                        'item_id': fmodItem.id,
                                        'price': parseFloat(this.groupItemPrice[`${product.menu_item.id}_${freeProduct.menu_item.id}_${fmodItem.id}`]), // freeProduct.menu_item.price,
                                        'modifier_group_id': fmodifier.mod_id,
                                        'pos_id':"0",// this.groupItemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}_${fmodItem.id}`],
                                    })
                                }
                            }
                            freeItems.push({
                                'item_id': freeProduct.menu_item.id,
                                'price': parseFloat(this.itemPrice[`${product.menu_item.id}_${freeProduct.menu_item.id}`]), //product.menu_item.price,
                                'pos_id':"0",// this.itemPos[`${product.menu_item.id}_${freeProduct.menu_item.id}`],
                                'addons': faddons
                            })
                        }
                    }
                    // construct modifiers
                    const modifiers = this.modifiers.filter((x) => x.item_id == product.menu_item.id)
                    const addons = [];
                    for (const modifier of Object.values(modifiers)) {
                        for (const modItem of Object.values(modifier.items)) {
                          for(let px =0; px <  this.posConfigs.length; px++ ){
                              const modItemPos = this.groupItemPos[`${product.menu_item.id}_${modItem.id}_${this.posConfigs[px].id}_${modifier.mod_id}`] //  this.groupItemPos[product.menu_item.id + '_' + modItem.id + '_'+ this.posConfigs[px].id]
                              itemModPosCodes.push({
                                'pos_id':modItemPos,
                                'pos_systems':this.posConfigs[px].id,
                                'mod_group_id':modifier.mod_id,
                                'item_id':modItem.id,
                                'parent_item_id':product.menu_item.id
                              })
                            }
                            addons.push({
                                'item_id': modItem.id,
                                'price': parseFloat(this.groupItemPrice[product.menu_item.id + '_' + modItem.id]), // freeProduct.menu_item.price,
                                'modifier_group_id': modifier.mod_id,
                                'pos_id':"0",// this.groupItemPos[product.menu_item.id + '_' + modItem.id],
                            })
                        }
                    }

                    for (let x = 0; x < this.posConfigs.length; x++) {
                        itemPesapalPos.push({
                            'pos_id': this.itemPos[`${product.menu_item.id}_${this.posConfigs[x].id}`],
                            'pos_systems': this.posConfigs[x].id,
                            'item_id': product.menu_item.id,
                            'discount_code': this.discountCode[product.menu_item.id]
                        })
                    }
                    items.push({
                        'item_id': product.menu_item.id,
                        'price': parseFloat(this.itemPrice[product.menu_item.id]), //product.menu_item.price,
                        'price_override': parseFloat(this.itemPriceOverride[product.menu_item.id]),
                        'pos_id':"0",// this.itemPos[product.menu_item.id],
                        'generate_offer_tag': this.generateItemTag[product.menu_item.id],
                        'addons': addons,
                        'free_items': freeItems
                    });

                }
                if(items.length == 0 && this.applicableTo == 1){
                  this.spinner = 0;
                  toast.error("please add item")
                  return
                }
                if (this.offerType === 1 && this.itemType === 'same') {
                    this.offerType = 2;
                } else if (this.offerType === 1 && this.itemType === 'different') {
                    this.offerType = 5;
                }


                let selectedChannels = []
                if(!this.allChannelSelected){
                  selectedChannels = this.channels.flatMap(c => {
                    if(this.checkedChannel[c.id]) {
                      return [c.id]
                    }
                    return []
                  });

                  // Add foodie
                  if(this.checkedChannel[0]) {
                     selectedChannels.push(0)
                  }
                }


                this.startDate = moment(this.startDate).format('YYYY-MM-DDTHH:mm:ssZ');
                this.endDate = moment(this.endDate).format('YYYY-MM-DDTHH:mm:ssZ');
                const postData = {
                    "merchant_id": parseInt(this.merchantID),
                    "offer_type": parseInt(this.offerType),
                    "name": this.title,
                    "description": this.description,
                    "channel_inclusions": selectedChannels,
                    "branch_inclusions": this.includedBranches,
                    "status": 1,
                    "recurrence": this.recurring,
                    "image_urls": this.imageUrls,
                    "amount": parseFloat(this.discountVal),
                    "start_date": this.startDate,
                    "end_date": this.endDate,
                    "days_of_week": this.selectedDays,
                    "rounding_type": parseInt(this.roundingType),
                    "rounding_value": this.roundingVal != '' ? parseFloat(this.roundingVal) : 0,
                    "retain_offer_pricing": this.productReturnPrice,
                    "items": items,
                    "addons": [],
                    "generate_category": this.generateCategory,
                    "generate_offer_tag": this.generateTag,
                    "parent_offer_id": this.parrentOffer,
                    "category_id": this.offerCategory,
                    "offer_applies_to":this.applicableTo,
                    "for_value_above": parseFloat(this.valueAbove),
                    "quantity": parseInt(this.Itemquantity),
                    "offer_is_on": this.orderType,
                    "quantity_based_on":parseInt(this.quantityType),
                    "menu_item_pos":itemPesapalPos,
                    "mod_menu_item_pos":itemModPosCodes,
                    "free_menu_item_pos":freeitemPesapalPos,
                    "free_mod_menu_item_pos":modposItems,
                    "item_offer_tags":this.selecteItemTag
                }
                const jsonParseData = JSON.stringify(postData)
                const url = this.baseUrl + "/offers";
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json', 'Content-type': 'application/json' }
                };
                this.axios.post(url, jsonParseData, config).then((response) => {
                    if (response.status == 201) {
                        this.spinner = 0;
                        toast.success("Offer created successfully");
                        this.$router.push({
                            path: 'offers'
                        })
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                    toast.error("Error Creating Offer try again")
                }).finally(() => this.spinner = 0)
                return;
            }
        },

        async getItems() {
            this.itemLoading = true
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('page', this.itemPage);
            url.searchParams.set('size', this.itemsize);
            url.searchParams.set('merchant_id', this.merchantID);
            if (this.itemSearch) {
                url.searchParams.set('item_name', this.itemSearch);
            }
            url.searchParams.set('standalone', true);
            url.searchParams.set('status', 1);
            url.searchParams.set('list_type', 1);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.itemLoading = false
                    this.items = response.data.data;
                    this.totalPages = Math.ceil(response.data.total / this.itemsize)
                    this.totalRecords = response.data.total_count
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        },
        getCategories() {
            this.catLoading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.baseUrl}/menu_category`;
            url = new URL(url);
            url.searchParams.set('page', this.catPage);
            url.searchParams.set('size', this.catsize);
            url.searchParams.set('merchant_id', this.merchantID);
            url.searchParams.set('list_type', 1)
            if (this.catSearch != '') {
                url.searchParams.set('query', this.catSearch);
            }
            url.searchParams.set('status', 1);
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.categories = response.data.data;
                    this.catTotalPages = Math.ceil(response.data.total / this.catsize)
                    this.totalRecords = response.data.total
                    this.catTotalRecords = response.data;
                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.catLoading = false;
            })
        },
        async getThirdParties() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.channels = response.data.data
                }
            });
        },

    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]

}
</script>
